import { firebaseApp } from "../index"
// import * as firedata from "firebase/database";
import * as fireStore from "firebase/firestore";


// If succesful, return void. Otherwise, throw error.
export async function storeToDatabase(btcAddress, solanaAddress, signature, publicKey, timeout) {
    return new Promise(async (resolve, reject) => {

        try {
            console.log(`storeToDatabase(${btcAddress}, ${solanaAddress}, ${signature})`);
            setTimeout(() => { reject("Timeout. Please refresh your web page"); }, timeout);

            const db = fireStore.getFirestore(firebaseApp);
            // const db = firedata.getDatabase(firebaseApp);

            // const readReference = firedata.ref(db, `records/${btcAddress}/${signature}`);
            const writeReference = fireStore.collection(db, `${btcAddress}/${solanaAddress}/items`);
            const readReference = fireStore.collection(db, `${btcAddress}/${solanaAddress}/items`);
            const record = {
                timestamp: fireStore.serverTimestamp(),
                solanaAddress: solanaAddress,
                btcAddress: btcAddress,
                signature: signature,
                pubKey: publicKey
            };

            const querySnapshot  = await fireStore.getDocs(readReference);
            if (querySnapshot.size > 0) {
                // querySnapshot.forEach((doc) => { console.log(doc.id, " => ", doc.data()); });
                reject(`Already Claimed for BTC Address ${btcAddress}, Solana Address ${solanaAddress}`);
            }
            else {
                const docRef = await fireStore.addDoc(writeReference, record);
                console.log("Document written with ID: ", docRef.id);
                resolve();
            }
        }
        catch (error) {
            reject(`Database Error: ${error}`);
        }
    });
}


// // If succesful, return void. Otherwise, throw error.
// export async function storeToDatabase(btcAddress, solanaAddress, signature, timeout) {
//     return new Promise(async (resolve, reject) => {

//         try {
//             console.log(`storeToDatabase(${btcAddress}, ${solanaAddress}, ${signature})`);
//             setTimeout(() => { reject("Timeout. Please refresh your web page"); }, timeout);

//             const db = firedata.getDatabase(firebaseApp);

//             const readReference = firedata.ref(db, `records/${btcAddress}/${signature}`);
//             const writeReference = firedata.ref(db, `records/${btcAddress}/${signature}`);
//             const record = {
//                 timestamp: firedata.serverTimestamp(),
//                 solanaAddress: solanaAddress,
//             };

//             const readResult = (await firedata.get(readReference)).val();
//             if (readResult !== null && readResult !== undefined && Object.keys(readResult).length > 0) {
//                 firedata.goOffline(db);
//                 reject(`Already Claimed for (${btcAddress}, ${solanaAddress}, ${signature})`);
//             }

//             else {
//                 await firedata.push(writeReference, record);
//                 firedata.goOffline(db);
//                 resolve();
//             }
//         }
//         catch (error) {
//             firedata.goOffline(db);
//             reject(`Database Error: ${error}`);
//         }
//     });
// }


// // Permission Test. This operation is expected to throw error "PERMISSION_DENIED"
// async function readDb() {
//     const database = firedata.getDatabase(firebaseApp);
//     const reference = firedata.ref(database, `records`);
//     const result = await firedata.get(reference);
//     console.log(result.val());
// }