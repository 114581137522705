import { storeToDatabase } from "./database";
import { message } from 'antd'
import { PublicKey } from '@solana/web3.js'

let btcAddress = "";
let solanaAddress = "";
let signature = "";

export async function claimAirdropToDb(btcAddress, solanaAddress, signature, publicKey) {
    try {
        await storeToDatabase(btcAddress, solanaAddress, signature, publicKey, 10000);
        // DO NOT use alert in production environment for it will block the browser
        message.success("Sucessfully Claimed Your Airdrop!");
    }
    catch (error) {
        // DO NOT use alert in production environment for it will block the browser
        message.error(`Failed to Claim Your Airdrop.\nReason = ${error}`);
    }
}

export async function connectBtcWallet() {
    try {
      if (typeof window.unisat === 'undefined') {
        throw new Error('UniSat Wallet is not installed! Please install it first at https://unisat.io/download');
      }
  
      let accounts = await window.unisat.requestAccounts();
    //   console.log(`connect success, accounts = ${accounts}`);
  
      let btcAddress = (await window.unisat.getAccounts())[0];
    //   console.log(`btcAddress = ${btcAddress}`);
      return btcAddress;
    } catch (e) {
      throw e; 
    }
  }


export async function connectSolanaWallet() {
    console.log("connectSolanaWallet");
    btcAddress = "fake-btc-address";
    solanaAddress = "fake-solana-addressX";
    signature = "fake-signature";
    while (btcAddress.length < 50) btcAddress = btcAddress.concat(["0123456789"])
    while (solanaAddress.length < 50) solanaAddress = solanaAddress.concat(["0123456789"])
    while (signature.length < 200) signature = signature.concat(["0123456789"])

    claimAirdropToDb(btcAddress, solanaAddress, signature);

    try {
        await window.ethereum.enable();
        // ...
    } catch (e) {
        alert("failed to connect solana wallet");
    }
}

export function addressValidated(address) {
    try {
        new PublicKey(address);
        return true;
      } catch (e) {
        return false;
      }
}
